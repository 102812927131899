<template>
  <!--
    The view for the InstallationList-component.
    Displays all installations.
  -->
  <div>
    <FreshdeskList 
      ref="list"
      :installation-id="installationId"
    />
    <SupportRequestIssueList 
      ref="slist"
      class="mt-3"
      :installation-id="installationId"
    />
    <ItopsIssueList 
      ref="ilist"
      class="mt-3"
      :installation-id="installationId"
    />
  </div>
</template>

<script>
export default {
  name: "InstallationDetailFreshdesk",
  components: {
    FreshdeskList: () => import('@/components/Freshdesk/FreshdeskList.vue'),
    SupportRequestIssueList: () => import('@/components/Jira/SupportRequestIssueList.vue'),
    ItopsIssueList: () => import('@/components/Jira/ItopsIssueList.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  }
}
</script>